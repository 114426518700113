import classNames from 'classnames'
import React from 'react'
import ButtonWithModal from 'components/ButtonWithModal'
import Link from 'components/Link'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './MassAdvantageInformationButton.module.scss'
import cardsImage from './images/cards@2x.jpg'

type Props = {
  children: React.ReactNode
} & Component

export default function MassAdvantageInformationButton({ className, ...props }: Props) {
  return (
    <ButtonWithModal
      {...props}
      modal={
        <Text align="left" element="p" flush size="large">
          Your Mass Advantage Flex Card dollars can be used on a Bold membership.{' '}
          <Link to="https://massadvantage.com/media/505" color="purple">
            Need help with your card?
          </Link>
        </Text>
      }
      level="text"
      modalProps={{
        confirmText: 'Close',
        hideCancel: true,
        image: cardsImage,
      }}
      modalType="confirm"
      className={classNames('MassAdvantageInformationButton', styles.this, className)}
    />
  )
}
