import React from 'react'
import Head from 'components/Head'
import HeaderBanner from 'components/HeaderBanner'
import Layout from 'components/Layout'
import MassAdvantageInformationButton from 'components/MassAdvantageInformationButton'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import Home from 'components/landing/Home'
import storage from 'constants/storage'
import useRedirectToAppIfAuthed from 'hooks/use-redirect-to-app-if-authed'
import * as cookie from 'libs/cookie'
import { ReactComponent as CardSvg } from './images/card.svg'
import { ReactComponent as InformationSvg } from './images/information.svg'
import { ReactComponent as LogosSvg } from './images/logos.svg'

export default function HomeMassAdvantage() {
  useRedirectToAppIfAuthed()

  React.useEffect(() => {
    cookie.createCookie(storage.HOME_KEY, storage.MASS_ADVANTAGE)
  }, [])

  return (
    <Layout
      headerProps={{
        banner: (
          <HeaderBanner>
            <Row size="xxxsmall">
              <TextWithIcon icon={<CardSvg />} center inline>
                <TextWithIcon
                  icon={
                    <MassAdvantageInformationButton>
                      <InformationSvg />
                    </MassAdvantageInformationButton>
                  }
                  center
                  iconPosition="right"
                  inline
                  size="large"
                  weight="medium">
                  Eligible for Flex Card dollars
                </TextWithIcon>
              </TextWithIcon>
            </Row>
            <Text element="p" flush>
              Bold is a preferred health and wellness partner of Mass Advantage
            </Text>
          </HeaderBanner>
        ),
      }}
      className="HomeMassAdvantage"
      data-test-id="home">
      <Head title="Age Bold – Variety of online fitness classes for older adults" titleOnly />
      <Home
        heroProps={{
          logos: LogosSvg,
          partners: [],
          subtext: (
            <>
              Find better balance, build strength, and reduce pain with easy-to-follow online
              workouts personalized for you.{' '}
              <Text color="yellow" weight="semibold">
                Mass Advantage members can use their prepaid Flex Card
              </Text>{' '}
              to cover the cost of a Bold membership.
            </>
          ),
        }}
      />
    </Layout>
  )
}
