//import TextWithIcon from 'components/TextWithIcon'
import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
//import MediaObject from 'components/MediaObject'
import Text, { type TextProps } from 'components/Text'
import styles from './HeaderBanner.module.scss'

//import { ReactComponent as ArrowRightSvg } from './images/arrow---right.svg'

type Props = {
  children: React.ReactNode
} & TextProps

export default function HeaderBanner({ children, className, ...props }: Props) {
  return (
    <Text
      {...props}
      align="center"
      color="white"
      className={classNames('HeaderBanner', styles.this, className)}>
      <Container full>{children}</Container>
    </Text>
  )
}
